 
.containerOrderTest .checkboxMarkers {
    width: 20px;
    height: 20px;
    appearance: none;
    border-radius: 6px;
    vertical-align: middle;
    border: 1px solid #64748B;
    background-color: #FFF;
    outline: none;
    cursor: pointer;    
    -webkit-appearance: none;                                                                            
}

.containerOrderTest .checkboxMarkers:checked {
    background-color: #0075BF;
    appearance: auto;
    clip-path: circle(60% at 50% 50%);
    border: 1px solid #64748B;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;   
}

.containerOrderTest .sampleTypeBtns {
    text-transform: none;
}

.containerOrderTest .checkboxMarkers:disabled {
    border: 1px solid rgba(0, 0, 0, 0.38);
}